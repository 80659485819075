<script setup lang="ts">
import { Icon } from 'webcc-ui-components'

const { t } = useI18n()
const { $pwa, $pwaBanner } = useNuxtApp()

const content = computed(() => {
  const [before, content, after] = t('components.pwa.android').split(
    /<\/?link>/,
  )
  return { before, content, after }
})

function install() {
  $pwaBanner.close()
  $pwa?.install()
}

function close() {
  $pwaBanner.close()
  $pwa?.cancelInstall()
}
</script>

<template>
  <div class="absolute bottom-0 flex w-full bg-bgr-700 p-6 text-white">
    <div class="h-8 w-8 flex justify-center shrink-0 rounded-[10px] bg-bgr-100">
      <img
        src="~/assets/icons/interhome-bird.svg"
        width="24"
        height="24"
        alt="Interhome Logo"
      />
    </div>

    <div class="grow px-3 text-white">
      {{ content.before }}
      <NuxtLink
        class="link-sm font-bold [&&]:text-white hover:text-bgr-100"
        @click.prevent="install"
      >
        {{ content.content }}
      </NuxtLink>
      {{ content.after }}
    </div>

    <button @click="close">
      <Icon size="lg" graphic="close" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { Icon } from 'webcc-ui-components'

const { t } = useI18n()
const { $pwa, $pwaBanner } = useNuxtApp()

const message = computed(() => {
  const [before, after] = t('components.pwa.ios').split('<icon>')
  return { before, after }
})

onMounted(() => {
  useEventListener('resize', onResize, { passive: true })
})

function onResize() {}

function close() {
  $pwaBanner.close()
  $pwa?.cancelInstall()
}
</script>

<template>
  <div
    class="absolute bottom-6 z-40 w-full px-4"
    :class="{
      'on-top bottom-auto top-6': $device.isIPad || $device.isLandscape,
    }"
  >
    <div class="ios-promo flex rounded-lg bg-bgr-700 p-6 text-bgr-100 shadow">
      <div
        class="h-8 w-8 flex justify-center shrink-0 rounded-[10px] bg-bgr-100"
      >
        <img
          src="~/assets/icons/interhome-bird.svg"
          width="24"
          height="24"
          alt="Interhome Logo"
        />
      </div>

      <div class="grow px-3">
        {{ message.before }}
        <img class="mx-1 -mt-2 inline-block" src="/pwa/ios.svg" alt="share" />
        {{ message.after }}
      </div>
      <button @click="close">
        <Icon size="lg" graphic="close" />
      </button>
    </div>
  </div>
</template>

<style scoped>
.ios-promo {
  position: relative;
}

.ios-promo:after {
  position: absolute;
  height: 0;
  width: 0;
  content: '';
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
  border-top-color: theme('colors.bgr.700');
}

.on-top .ios-promo:after {
  top: auto;
  bottom: 100%;
  transform: none;
  right: 97px;
  left: auto;
  border: 10px solid transparent;
  border-top-color: theme('colors.bgr.700');
}
</style>
